import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { connect } from "react-redux";
import Link from "react-router-dom/Link";
import LogoInverted from "../static/img/logo-invert.png";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color: "white",
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  slogan: {
    marginTop: theme.spacing(0),
    fontSize: theme.typography.h3.fontSize,
    // fontFamily: ['Times New Roman', 'Times', 'serif'],
    // fontFamily: 'Monospace',
    fontWeight: 600,
    letterSpacing: 3,
  },
  subtitle: {
    marginTop: theme.spacing(2),
    fontFamily: 'Monospace',
    letterSpacing: 2,
    fontSize: theme.typography.subtitle2.fontSize,
  }
}));

function Home(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Box className={classes.slogan}>
          Schweißtechnik
        </Box>
        <Box className={classes.slogan}>
          Rohrleitungsbau
        </Box>
        <Box className={classes.subtitle}>
        Industrie | Kommunen | Privat
        </Box>
        <Link to="/services" className={classes.link}>
          {/* <Button onClick={props.showHideNavigation}> */}
          <Button>
            {<img src={LogoInverted} style={{ width: "100%" }} alt="MILVIK" />}
          </Button>
        </Link>
        <Box className={classes.slogan}>
          Industrie-Service
        </Box>
        <Box className={classes.subtitle}>
          Berlin
        </Box>
      </Box>
    </React.Fragment>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    showHideNavigation: () => {
      dispatch({ type: "SHOW_HIDE_NAVIGATION" });
    }
  };
}

function mapStateToProps(state) {
  return {
    num: state.num,
    counterHidden: state.counterHidden,
    navigationHidden: state.navigationHidden,
    openedCard: state.openedCard
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
