import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  card: {
    display: "flex",
    backgroundColor: "rgba(255, 255, 255, 0.6)"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: 180
  },
  cardMediaTop: {
    height: 0,
    paddingTop: "53%" // 16:9
  }
  // overlay: {
  //   position: 'absolute',
  //   top: 0,
  //   bottom: 0,
  //   right: 0,
  //   left: 0,
  //   // backgroundColor: 'rgba(0,0,0,.3)',
  //   backgroundColor: 'rgba(96, 125, 139, 0.6)',
  // },
});

export default function CardGrid(props) {
  const classes = useStyles();
  const { post } = props;

  return (
    <div className={classes.root}>
      <Box borderColor="grey.100" border={2} borderRadius={6}>
        <CardActionArea component="a" href="#">
          {/* <Hidden xsDown> */}
            <CardMedia
              className={classes.cardMediaTop}
              image={post.image}
              title={post.imageTitle}
            />
          {/* </Hidden> */}
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h5">{post.title}</Typography>
              <Typography variant="subtitle2" paragraph>
                {post.description}
              </Typography>
              {/* <Typography variant="subtitle1" color="primary">
                Continue reading...
              </Typography> */}
            </CardContent>
            {/* <Hidden xsDown>
              <CardMedia
                className={classes.cardMedia}
                image={post.image}
                title={post.imageTitle}
              />
            </Hidden> */}
          </Card>
        </CardActionArea>
      </Box>
    </div>
  );
}

CardGrid.propTypes = {
  post: PropTypes.object
};
