import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Header from "./common/Header";
import InfoCard from "./common/InfoCard";
import CardImageHeaderX from "../static/img/contact.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    height: "100%"
  },
  mainGrid: {
    padding: theme.spacing(1),
    justifyContent: "space-around"
  }
}));

const desc = {
  title: "Kontakt",
  description: "Fragen Sie einfach, ob wir helfen können.",
  image: `url(${CardImageHeaderX})`,
  imgText: "main image description"
  // linkText: 'Continue reading…',
};

const contacts = [
  {
    title: "Telefon",
    description: "030 240 376 77",
    sm: 4,
    contentVariant: "h4",
    href: "tel:03024037677"
  },
  {
    title: "E-Mail",
    description: "info@milvik.org",
    sm: 4,
    contentVariant: "h4",
    href: "mailto:info@milvik.org"
  },
  {
    title: "Telefax",
    description: "030 240 376 76",
    sm: 4,
    contentVariant: "h4",
    href: "#"
  }
  // {
  //   title: 'Postanschrift',
  //   description: 'MILVIK Bau- und Projektsteuerung GmbH Bulgenbachweg 21 A | 13465 Berlin',
  //   sm: 10,
  //   contentVariant: 'body1'
  // },
];

export default function Contact() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.root}>
        <Header post={desc} />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.mainGrid}
        >
          {contacts.map(item => (
            <InfoCard key={item.title} info={item} />
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
