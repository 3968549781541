import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";



let theme = createMuiTheme({
  palette: {
    secondary: grey,
    primary: grey,
  }
})
theme = responsiveFontSizes(theme);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
