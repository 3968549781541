import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Header from "./common/Header";
import CardGrid from "./common/CardGrid";
import CardImageIndustry from "../static/img/close-up.jpg";
import CardImageSchweisstechnik from "../static/img/schweisstechnik.jpg";
import CardImageIndustrieanlagen from "../static/img/industrieanlage.jpg";
import CardImageRohrleitungsbau from "../static/img/rohrleitungsbau.jpg";
// import CardImageProjektsteuerung from '../static/img/projektsteuerung.jpg';

const mainFeaturedPost = {
  title: "Leistungen",
  description: "Wir machen das, was wir am besten können",
  image: `url(${CardImageIndustry})`,
  imgText: "main image description",
  // linkText: "Unser Angebot richtet sich an alle Unternehmen in Berlin, die sowhol kurzfristige Unterstützung wünschen, als auch einen erfahrenen Partner für größere Projekte suchen."
};

const primaryServices = [
  {
    title: "Schweißtechnik",
    date: "Nov 11",
    description:
      "Schweißverfahren wie \
      MMA - Manuelles Lichtbogenschweißen von Metallen mit beschichteter Elektrode (Elektro-Schweißen), \
      TIG/WIG - Wolframmetallschweißen für präzise, spritzerfreie Schweißnähte, \
      MAG - Schweißen von Leichtmetallen unter Inertgas (Argon), \
      MIG - Schweißen von Metallen in Schutzatmosphäre mit Aktivgas (Stickstoff). \
      Wir können Sie mit fachlich kompetenter Beratung und Ausführung bei der Einzelanfertigung oder der Anfertigung von Sonderkonstruktionen unterstützen. \
      ",
    image: `${CardImageSchweisstechnik}`,
    imageText: "Image Text"
  },
  {
    title: "Rohrleitungsbau",
    date: "Nov 11",
    description:
      "Wir bauen Rohrleitungen aller Art: \
      für den Transport von Wasser, Abwässern, \
      für die Fernwärmeversorgung \
      und auch für verschiedenen Gase und Flüssigkeiten (Pipelines).  \
      Deshalb ist die starke Chemie- und Petrochemie-Industrie am Standort Berlin kein Neuland für uns. \
      Die MILVIK GmbH ist der erfahrene Ansprechpartner für Raffinerien, Kraftwerke und Versorgungsunternehmen in Fragen \
      Anlagenbau aus Stahl und Edelstahl, Wartung und Instandhaltung.\
      ",
    image: `${CardImageRohrleitungsbau}`,
    imageText: "Image Text"
  },
  {
    title: "Industrie-Service",
    date: "Nov 11",
    description:
      <div>Dienstleistungen im Bereich Industrie-Service für Ihren Produktionsstandort in Berlin gehören ebenfalls zu unseren Kompetenzen:
        <li>Montage-, Demontagenarbeiten und Reparaturen von Industrieanlagen (Kessel, Klimaanlagen, Kälteanlagen, Heizanlagen)</li>
        <li>Industriereinigung</li>
      </div>,
    image: `${CardImageIndustrieanlagen}`,
    imageText: "Image Text"
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    height: "100%"
  },
  mainGrid: {
    padding: theme.spacing(1)
  },
  item: {
    padding: theme.spacing(2)
  }
}));

export default function Services() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.root}>
        <Header post={mainFeaturedPost} />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.mainGrid}
        >
          {primaryServices.map(item => (
            <Grid item xs={12} sm={4} className={classes.item}>
              <CardGrid key={item.title} post={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
