import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Link from "react-router-dom/Link";
import Image from "../static/img/logo.png";
import InfoItem from "./InfoItem";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import HamburgerMenu from "./HamburgerMenu";

const useStyles = makeStyles(theme => ({
  grid: {
    flexGrow: 1,
    // display: 'flex',
    justifyContent: "space-around",
    alignItems: "center",
    // color: 'black',
    background: "white",
    opacity: "0.85",
    padding: theme.spacing(1)
  },
  barLeft: {
    // backgroundColor: 'red',
    display: "flex",
    justifyContent: "center"
  },
  barCenter: {
    // backgroundColor: 'blue',
    display: "flex",
    justifyContent: "center"
  },
  barRight: {
    // backgroundColor: 'red',
    display: "flex",
    justifyContent: "center"
  },
  menuButton: {
    // backgroundColor: 'blue',
  },
  link: {
    textDecoration: "none"
  },
  bigLogo: {
    width: "75%",
    height: "75%"
  }
}));

export function HeadNavBar(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {/* <div className={classes.root}> */}
      {/* <Box borderColor="grey.100" border={2}> */}
      <Grid container className={classes.grid}>
        <Grid item xs={3} className={classes.barLeft}>
          <Hidden mdUp>
            <HamburgerMenu />
          </Hidden>
          <Hidden smDown>
            <Link className={classes.link} to="/">
              <Button>Home</Button>
            </Link>
            <Link className={classes.link} to="/services">
              <Button>Leistungen</Button>
            </Link>
            <Link className={classes.link} to="/contact">
              <Button>Kontakt</Button>
            </Link>
          </Hidden>
        </Grid>
        <Grid item xs={6} className={classes.barCenter}>
          {/* <Button onClick={props.showHideCounter} className={classes.menuButton}> */}
          {<img src={Image} className={classes.bigLogo} alt="MILVIK" />}
          {/* </Button> */}
        </Grid>
        <Grid item xs={3} className={classes.barRight}>
          <InfoItem />
        </Grid>
      </Grid>
      {/* </Box> */}
      {/* </div> */}
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    num: state.num,
    counterHidden: state.counterHidden
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showHideCounter: () => {
      dispatch({ type: "SHOW_HIDE_COUNTER" });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeadNavBar);
