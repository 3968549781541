import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { connect } from "react-redux/";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Impressum from "./pages/Impressum";
import PrivacyDataProtection from "./pages/PrivacyDataProtection";
import HeadNavBar from "./components/HeadNavBar";
import Footer from "./components/Footer";
import Header from "./pages/common/Header";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import BackgroundImage from "./static/img/background.jpg";
import CssBaseline from "@material-ui/core/CssBaseline";
import CardImageIndustry from "./static/img/close-up.jpg";

const useStyles = makeStyles(theme => ({
  "@global": {
    // MUI typography elements use REMs, so you can scale the global
    // font size by setting the font-size on the <html> element.
    html: {
      // height: '100%',
    },
    body: {
      height: "inherit",
      backgroundImage: `url(${BackgroundImage})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      // backgroundRepeat: 'no-repeat',
      backgroundColor: "dimgray",
      // opacity: 0.5,
      backgroundBlendMode: "multiply"
      // backgroundBlendMode: 'darken',
    }
  },

  root: {
    width: "100%",
    // height: '100%',
    display: "flex",
    // flexDirection: 'row',
    // alignItems: 'center',
    justifyContent: "center",
    padding: theme.spacing(0)
  },
  grid: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    justifyItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "80%"
      // backgroundColor: 'rgba(234, 125, 139, 0.4)',
    },
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    padding: theme.spacing(0)
  },

  header: {
    // display: 'flex',
    padding: theme.spacing(0),
    position: "sticky",
    top: 0
  },
  content: {
    // display: 'flex',
    minHeight: "fit-content",
    padding: theme.spacing(0)
    // position: 'relative',
    // bottom: 0,
  },
  footer: {
    // display: 'flex',
    padding: theme.spacing(0),
    position: "sticky",
    bottom: 0
    // backgroundColor: 'red',
    // opacity: 0.5,
  }
}));

// const mainFeaturedPost = {
//   title: 'Leistungen',
//   description:
//     "Wir machen das, was wir am besten können",
//   image: `url(${CardImageIndustry})`,
//   imgText: 'main image description',
//   linkText: 'Continue reading…',
// };

function App(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <BrowserRouter>
        <Container className={classes.root}>
          <Box className={classes.grid}>
            <Box className={classes.header}>
              {!props.navigationHidden && <HeadNavBar />}
              {/* <Header post={mainFeaturedPost}  /> */}
            </Box>

            <Box className={classes.content}>
              <Route exact path="/" component={Home} />
              <Route path="/services" component={Services} />
              <Route path="/contact" component={Contact} />
              <Route path="/impressum" component={Impressum} />
              <Route
                path="/privacyDataProtection"
                component={PrivacyDataProtection}
              />
            </Box>
            <Box className={classes.footer}>
              {!props.navigationHidden && <Footer />}
            </Box>
          </Box>
        </Container>
      </BrowserRouter>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    num: state.num,
    counterHidden: state.counterHidden,
    navigationHidden: state.navigationHidden,
    openedCard: state.openedCard
  };
}

export default connect(mapStateToProps)(App);
