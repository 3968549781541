import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "./common/Header";
import CardImageIndustry from "../static/img/industrieanlage.jpg";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    height: "100%"
  },
  mainGrid: {
    padding: theme.spacing(1),
    justifyContent: "space-around"
  },
  item: {
    padding: theme.spacing(1)
  },
  headingPaper: {
    padding: theme.spacing(2)
  },
  contentPaper: {
    padding: theme.spacing(2),
    textAlign: "left"
  },
  card: {
    padding: theme.spacing(0),
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    alignItems: "center",
    height: "100%"
  }
}));

const mainFeaturedPost = {
  title: "IMPRESSUM",
  description: " ",
  image: `url(${CardImageIndustry})`,
  imgText: "main image description"
  // linkText: "Continue reading…"
};

function Impressum() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.root}>
        <Header post={mainFeaturedPost} />
        <Grid container className={classes.mainGrid}>
          <Grid item xs={12} sm={4} className={classes.item}>
            <Box borderColor="grey.100" border={2} borderRadius={6}>
              <Card className={classes.card}>
                <Container className={classes.headingPaper}>
                  <Typography variant="h5">Angaben gemäß § 5 TMG:</Typography>
                </Container>
                <Container className={classes.contentPaper}>
                  <Typography variant="h6">Unternehmen:</Typography>
                  <Typography variant="body1">
                    MILVIK Bau- und Projektsteuerung GmbH
                  </Typography>
                </Container>
                <Container className={classes.contentPaper}>
                  <Typography variant="h6">Geschäftsführer:</Typography>
                  <Typography variant="body1">Zoran Vukojevic</Typography>
                </Container>
                <Container className={classes.contentPaper}>
                  <Typography variant="h6">Postanschrift:</Typography>
                  <Typography variant="body1" className={classes.content}>
                    Bulgenbachweg 21 A<br />
                    13465 Berlin
                  </Typography>
                </Container>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.item}>
            <Box borderColor="grey.100" border={2} borderRadius={6}>
              <Card className={classes.card}>
                <Container className={classes.headingPaper}>
                  <Typography variant="h5"> ... </Typography>
                </Container>
                <Container className={classes.contentPaper}>
                  <Typography variant="h6">Kontakt:</Typography>
                  <Typography variant="body1">
                    Telefon: +49 30 240 376 77
                    <br />
                    Telefax: +49 30 240 376 76
                    <br />
                    E-Mail: info@milvik.org
                  </Typography>
                </Container>
                <Container className={classes.contentPaper}>
                  <Typography variant="h6">Handelsregister:</Typography>
                  <Typography body2="true">
                    Amtsgericht: Berlin-Charlottenburg
                    <br />
                    Register-Nr.: HRB 204060 B<br />
                    Steuer-Nr.: 30/444/50114
                    <br />
                    USt-IdNr.: 322 330 565
                  </Typography>
                </Container>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.item}>
            <Box borderColor="grey.100" border={2} borderRadius={6}>
              <Card className={classes.card}>
                <Container className={classes.headingPaper}>
                  <Typography variant="h5">Hinweise zur Website:</Typography>
                </Container>
                <Container className={classes.contentPaper}>
                  <Typography variant="h6">Haftungshinweis:</Typography>
                  <Typography body2="true">
                    Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir
                    keine Haftung für die Inhalte externer Links. Für den Inhalt
                    der verlinkten Seiten sind ausschließlich deren Betreiber
                    verantwortlich.
                  </Typography>
                </Container>
                <Container className={classes.contentPaper}>
                  <Typography variant="h6">
                    Urheberrechtliche Hinweise:
                  </Typography>
                  <Typography variant="body1">
                    Bilder: pexels.com
                    <br />
                  </Typography>
                </Container>
                <Container className={classes.contentPaper}>
                  <Typography variant="h6">Design und Umsetzung:</Typography>
                  <Typography variant="body1">sstyle.org</Typography>
                </Container>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Impressum;
