import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "./common/Header";
import CardImageIndustry from "../static/img/industrieanlage.jpg";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    height: "100%"
  },
  mainGrid: {
    padding: theme.spacing(1),
    justifyContent: "space-around"
  },
  headingPaper: {
    display: "flex",
    color: "white"
  },
  item: {
    padding: theme.spacing(1),
    // backgroundColor: 'rgba(255, 255, 255, 0.6)',
    textOverflow: "ellipsis"
  },
  text: {
    fontSize: "small",
    textOverflow: "ellipsis"
  },
  link: {
    textDecoration: "none",
    color: "white"
  },
  card: {
    padding: theme.spacing(1),
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    alignItems: "center"
  }
}));

const mainFeaturedPost = {
  title: "Datenschutz",
  description: " ",
  image: `url(${CardImageIndustry})`,
  imgText: "Datenschutz"
  // linkText: "Wir achten auf Ihre Privatsphäre"
};

function PrivacyDataProtection() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.root}>
        <Header post={mainFeaturedPost} />
        <Grid container className={classes.mainGrid}>
          <Grid item xs={12} sm={6} className={classes.item}>
            <Box borderColor="grey.100" border={2} borderRadius={6}>
              <Card className={classes.card}>
                <Typography variant="subtitle1" gutterBottom>
                  Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere
                  der EU-Datenschutzgrundverordnung (DSGVO), ist:
                </Typography>
                <Typography paragraph variant="body2">
                  MILVIK Bau- und Projektsteuerung GmbH
                  <br />
                  Bulgenbachweg 21 A<br />
                  13465 Berlin
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Ihre Betroffenenrechte
                </Typography>
                <Typography paragraph variant="body2">
                  Unter den angegebenen Kontaktdaten unseres
                  Datenschutzbeauftragten können Sie jederzeit folgende Rechte
                  ausüben:
                </Typography>
                <List>
                  <ListItem>
                    Auskunft über Ihre bei uns gespeicherten Daten und deren
                    Verarbeitung (Art. 15 DSGVO),
                  </ListItem>
                  <ListItem>
                    Berichtigung unrichtiger personenbezogener Daten (Art. 16
                    DSGVO),
                  </ListItem>
                  <ListItem>
                    Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),
                  </ListItem>
                  <ListItem>
                    Einschränkung der Datenverarbeitung, sofern wir Ihre Daten
                    aufgrund gesetzListItemcher Pflichten noch nicht löschen
                    dürfen (Art. 18 DSGVO),
                  </ListItem>
                  <ListItem>
                    Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art.
                    21 DSGVO) und
                  </ListItem>
                  <ListItem>
                    Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
                    eingewilligt haben oder einen Vertrag mit uns abgeschlossen
                    haben (Art. 20 DSGVO).
                  </ListItem>
                </List>
                <Typography paragraph variant="body2">
                  Sofern Sie uns eine Einwilligung erteilt haben, können Sie
                  diese jederzeit mit Wirkung für die Zukunft widerrufen.
                </Typography>
                <Typography paragraph variant="body2">
                  Sie können sich jederzeit mit einer Beschwerde an eine
                  Aufsichtsbehörde wenden, z. B. an die zuständige
                  Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die
                  für uns als verantwortliche Stelle zuständige Behörde.
                </Typography>
                {/* <Typography className={classes.text} paragraph variant="body2" >
                  Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie
                  unter: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank"
                    rel="nofollow noopener">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
                </Typography> */}
                <Typography paragraph variant="body2"></Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Erfassung allgemeiner Informationen beim Besuch unserer
                  Website
                </Typography>
                <Typography variant="subtitle2">
                  Art und Zweck der Verarbeitung:
                </Typography>
                <Typography paragraph variant="body2">
                  Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich
                  nicht registrieren oder anderweitig Informationen übermitteln,
                  werden automatisch Informationen allgemeiner Natur erfasst.
                  Diese Informationen (Server-Logfiles) beinhalten etwa die Art
                  des Webbrowsers, das verwendete Betriebssystem, den
                  Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse
                  und ähnliches.
                </Typography>
                <Typography paragraph variant="body2">
                  Sie werden insbesondere zu folgenden Zwecken verarbeitet:
                </Typography>
                <List>
                  <ListItem>
                    Sicherstellung eines problemlosen Verbindungsaufbaus der
                    Website,
                  </ListItem>
                  <ListItem>
                    Sicherstellung einer reibungslosen Nutzung unserer Website,
                  </ListItem>
                  <ListItem>
                    Auswertung der Systemsicherheit und -stabiListItemtät sowie
                  </ListItem>
                  <ListItem>zu weiteren administrativen Zwecken.</ListItem>
                </List>
                <Typography paragraph variant="body2">
                  Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre
                  Person zu ziehen. Informationen dieser Art werden von uns
                  ggfs. statistisch ausgewertet, um unseren Internetauftritt und
                  die dahinterstehende Technik zu optimieren.
                </Typography>
                <Typography variant="subtitle2">Rechtsgrundlage:</Typography>
                <Typography paragraph variant="body2">
                  Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf
                  Basis unseres berechtigten Interesses an der Verbesserung der
                  Stabilität und Funktionalität unserer Website.
                </Typography>
                <Typography variant="subtitle2">Empfänger:</Typography>
                <Typography paragraph variant="body2">
                  Empfänger der Daten sind ggf. technische Dienstleister, die
                  für den Betrieb und die Wartung unserer Webseite als
                  Auftragsverarbeiter tätig werden.
                </Typography>
                <Typography variant="subtitle2">Speicherdauer:</Typography>
                <Typography paragraph variant="body2">
                  Die Daten werden gelöscht, sobald diese für den Zweck der
                  Erhebung nicht mehr erforderlich sind. Dies ist für die Daten,
                  die der Bereitstellung der Webseite dienen, grundsätzlich der
                  Fall, wenn die jeweilige Sitzung beendet ist.
                </Typography>
                <Typography variant="subtitle2">
                  Bereitstellung vorgeschrieben oder erforderlich:
                </Typography>
                <Typography paragraph variant="body2">
                  Die Bereitstellung der vorgenannten personenbezogenen Daten
                  ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die
                  IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit
                  unserer Website nicht gewährleistet. Zudem können einzelne
                  Dienste und Services nicht verfügbar oder eingeschränkt sein.
                  Aus diesem Grund ist ein Widerspruch ausgeschlossen.
                </Typography>
                <Typography paragraph variant="body2"></Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Verwendung von Google Analytics
                </Typography>
                <Typography variant="subtitle2">
                  Art und Zweck der Verarbeitung:
                </Typography>
                <Typography paragraph variant="body2">
                  Diese Website benutzt Google Analytics, einen Webanalysedienst
                  der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
                  94043 USA (nachfolgend: „Google“). Google Analytics verwendet
                  sog. „Cookies“, also Textdateien, die auf Ihrem Computer
                  gespeichert werden und die eine Analyse der Benutzung der
                  Webseite durch Sie ermöglichen. Die durch das Cookie erzeugten
                  Informationen über Ihre Benutzung dieser Webseite werden in
                  der Regel an einen Server von Google in den USA übertragen und
                  dort gespeichert. Aufgrund der Aktivierung der
                  IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse
                  von Google jedoch innerhalb von Mitgliedstaaten der
                  Europäischen Union oder in anderen Vertragsstaaten des
                  Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.
                  Nur in Ausnahmefällen wird die volle IP-Adresse an einen
                  Server von Google in den USA übertragen und dort gekürzt. Im
                  Auftrag des Betreibers dieser Website wird Google diese
                  Informationen benutzen, um Ihre Nutzung der Webseite
                  auszuwerten, um Reports über die Webseitenaktivitäten
                  zusammenzustellen und um weitere mit der Websitenutzung und
                  der Internetnutzung verbundene Dienstleistungen gegenüber dem
                  Webseitenbetreiber zu erbringen. Die im Rahmen von Google
                  Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht
                  mit anderen Daten von Google zusammengeführt.
                </Typography>
                <Typography paragraph variant="body2">
                  Die Zwecke der Datenverarbeitung liegen in der Auswertung der
                  Nutzung der Website und in der Zusammenstellung von Reports
                  über Aktivitäten auf der Website. Auf Grundlage der Nutzung
                  der Website und des Internets sollen dann weitere verbundene
                  Dienstleistungen erbracht werden.
                </Typography>
                <Typography variant="subtitle2">Rechtsgrundlage:</Typography>
                <Typography paragraph variant="body2">
                  Die Verarbeitung der Daten erfolgt auf Grundlage einer
                  Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).
                </Typography>
                <Typography variant="subtitle2">Empfänger:</Typography>
                <Typography paragraph variant="body2">
                  Empfänger der Daten ist Google als Auftragsverarbeiter.
                  Hierfür haben wir mit Google den entsprechenden
                  {/* Auftragsverarbeitungsvertrag abgeschlossen. */}
                </Typography>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.item}>
            <Box borderColor="grey.100" border={2} borderRadius={6}>
              <Card className={classes.card}>
                <Typography variant="subtitle2">Speicherdauer:</Typography>
                <Typography paragraph variant="body2">
                  Die Löschung der Daten erfolgt, sobald diese für unsere
                  Aufzeichnungszwecke nicht mehr erforderlich sind.
                </Typography>
                <Typography variant="subtitle2">Drittlandtransfer:</Typography>
                <Typography paragraph variant="body2">
                  Google verarbeitet Ihre Daten in den USA und hat sich dem
                  EU_US Privacy Shield unterworfen{" "}
                  <a href="https://www.privacyshield.gov/EU-US-Framework">
                    https://www.privacyshield.gov/EU-US-Framework
                  </a>
                  .
                </Typography>
                <Typography variant="subtitle2">
                  Bereitstellung vorgeschrieben oder erforderlich:
                </Typography>
                <Typography paragraph variant="body2">
                  Die Bereitstellung Ihrer personenbezogenen Daten erfolgt
                  freiwillig, allein auf Basis Ihrer Einwilligung. Sofern Sie
                  den Zugriff unterbinden, kann es hierdurch zu
                  Funktionseinschränkungen auf der Website kommen.
                </Typography>
                <Typography variant="subtitle2">
                  Widerruf der Einwilligung:
                </Typography>
                <Typography paragraph variant="body2">
                  Sie können die Speicherung der Cookies durch eine
                  entsprechende Einstellung Ihrer Browser-Software verhindern;
                  wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                  gegebenenfalls nicht sämtliche Funktionen dieser Website
                  vollumfänglich werden nutzen können. Sie können darüber hinaus
                  die Erfassung der durch das Cookie erzeugten und auf Ihre
                  Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse)
                  an Google sowie die Verarbeitung dieser Daten durch Google
                  verhindern, indem sie das unter dem folgenden Link verfügbare
                  Browser-Plugin herunterladen und installieren:{" "}
                  <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
                    Browser Add On zur Deaktivierung von Google Analytics
                  </a>
                  .
                </Typography>
                <Typography paragraph variant="body2">
                  Zusätzlich oder als Alternative zum Browser-Add-On können Sie
                  das Tracking durch Google Analytics auf unseren Seiten
                  unterbinden, indem Sie
                  <a
                    title="Google Analytics Opt-Out-Cookie setzen"
                    onClick="gaOptout();alert('Google Analytics wurde deaktiviert');"
                    href="#"
                  >
                    diesen Link anklicken
                  </a>
                  . Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert.
                  Damit wird die Erfassung durch Google Analytics für diese
                  Website und für diesen Browser zukünftig verhindert, so lange
                  das Cookie in Ihrem Browser installiert bleibt.
                </Typography>
                <Typography variant="subtitle2">Profiling:</Typography>
                <Typography paragraph variant="body2">
                  Mit Hilfe des Tracking-Tools Google Analytics kann das
                  Verhalten der Besucher der Webseite bewertet und die
                  Interessen analysiert werden. Hierzu erstellen wir ein
                  pseudonymes Nutzerprofil.
                </Typography>
                <Typography paragraph variant="body2"></Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Verwendung von Scriptbibliotheken (Google Webfonts)
                </Typography>
                <Typography variant="subtitle2">
                  Art und Zweck der Verarbeitung:
                </Typography>
                <Typography paragraph variant="body2">
                  Um unsere Inhalte browserübergreifend korrekt und grafisch
                  ansprechend darzustellen, verwenden wir auf dieser Website
                  „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway,
                  Mountain View, CA 94043, USA; nachfolgend „Google“) zur
                  Darstellung von Schriften.
                </Typography>
                <Typography paragraph variant="body2">
                  Die Datenschutzrichtlinie des Bibliothekbetreibers Google
                  finden Sie hier:{" "}
                  <a href="https://www.google.com/policies/privacy/">
                    https://www.google.com/policies/privacy/
                  </a>
                </Typography>
                <Typography variant="subtitle2">Rechtsgrundlage:</Typography>
                <Typography paragraph variant="body2">
                  Rechtsgrundlage für die Einbindung von Google Webfonts und dem
                  damit verbundenen Datentransfer zu Google ist Ihre
                  Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).
                </Typography>
                <Typography variant="subtitle2">Empfänger:</Typography>
                <Typography paragraph variant="body2">
                  Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken
                  löst automatisch eine Verbindung zum Betreiber der Bibliothek
                  aus. Dabei ist es theoretisch möglich – aktuell allerdings
                  auch unklar ob und ggf. zu welchen Zwecken – dass der
                  Betreiber in diesem Fall Google Daten erhebt.
                </Typography>
                <Typography variant="subtitle2">Speicherdauer:</Typography>
                <Typography paragraph variant="body2">
                  Wir erheben keine personenbezogenen Daten, durch die
                  Einbindung von Google Webfonts.
                </Typography>
                <Typography paragraph variant="body2">
                  Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
                  <a href="https://developers.google.com/fonts/faq">
                    https://developers.google.com/fonts/faq
                  </a>{" "}
                  und in der Datenschutzerklärung von Google:{" "}
                  <a href="https://www.google.com/policies/privacy/">
                    https://www.google.com/policies/privacy/
                  </a>
                  .
                </Typography>
                <Typography variant="subtitle2">Drittlandtransfer:</Typography>
                <Typography paragraph variant="body2">
                  Google verarbeitet Ihre Daten in den USA und hat sich dem
                  EU_US Privacy Shield unterworfen{" "}
                  <a href="https://www.privacyshield.gov/EU-US-Framework">
                    https://www.privacyshield.gov/EU-US-Framework
                  </a>
                  .
                </Typography>
                <Typography variant="subtitle2">
                  Bereitstellung vorgeschrieben oder erforderlich:
                </Typography>
                <Typography paragraph variant="body2">
                  Die Bereitstellung der personenbezogenen Daten ist weder
                  gesetzlich, noch vertraglich vorgeschrieben. Allerdings kann
                  ggfs. die korrekte Darstellung der Inhalte durch
                  Standardschriften nicht möglich sein.
                </Typography>
                <Typography variant="subtitle2">
                  Widerruf der Einwilligung:
                </Typography>
                <Typography paragraph variant="body2">
                  Zur Darstellung der Inhalte wird regelmäßig die
                  Programmiersprache JavaScript verwendet. Sie können der
                  Datenverarbeitung daher widersprechen, indem Sie die
                  Ausführung von JavaScript in Ihrem Browser deaktivieren oder
                  einen JavaScript-Blocker installieren. Bitte beachten Sie,
                  dass es hierdurch zu Funktionseinschränkungen auf der Website
                  kommen kann.
                </Typography>
                <Typography paragraph variant="body2"></Typography>
                <Typography variant="subtitle1" gutterBottom>
                  SSL-Verschlüsselung
                </Typography>
                <Typography paragraph variant="body2">
                  Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
                  verwenden wir dem aktuellen Stand der Technik entsprechende
                  Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
                </Typography>
                <Typography paragraph variant="body2"></Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Eingesetzte Auftragsverarbeiter
                </Typography>
                <Typography paragraph variant="body2">
                  Folgende Organisationen, Unternehmen bzw. Personen wurden vom
                  Betreiber dieser Website mit der Verarbeitung von Daten
                  beauftragt:
                </Typography>
                <Typography paragraph variant="body2">
                  1&1 IONOS SE
                  <br />
                  Elgendorfer Str. 57
                  <br />
                  56410 Montabaur
                </Typography>
                <Typography paragraph variant="body2"></Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Änderung unserer Datenschutzbestimmungen
                </Typography>
                <Typography paragraph variant="body2">
                  Wir behalten uns vor, diese Datenschutzerklärung anzupassen,
                  damit sie stets den aktuellen rechtlichen Anforderungen
                  entspricht oder um Änderungen unserer Leistungen in der
                  Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer
                  Services. Für Ihren erneuten Besuch gilt dann die neue
                  Datenschutzerklärung.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Fragen an den Datenschutzbeauftragten
                </Typography>
                <Typography paragraph variant="body2">
                  Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte
                  eine E-Mail oder wenden Sie sich direkt an die für den
                  Datenschutz verantwortliche Person in unserer Organisation:
                </Typography>
                <Typography paragraph variant="body2">
                  datenschutz@milvik.org
                </Typography>
                <Typography paragraph variant="body2"></Typography>
                <Typography paragraph variant="body2">
                  <em>
                    Die Datenschutzerklärung wurde mithilfe der activeMind AG
                    erstellt, den Experten für
                    <a
                      href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/"
                      target="_blank"
                      rel="noopener"
                    >
                      externe Datenschutzbeauftragte
                    </a>
                  </em>
                </Typography>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default PrivacyDataProtection;
