import React from "react";
import makeStyles from '@material-ui/core/styles/makeStyles';
import withWidth from '@material-ui/core/withWidth';
import Phone from '@material-ui/icons/Phone';
import Mail from '@material-ui/icons/Mail';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';


const useStyles = makeStyles(theme => ({

  grid: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%'
  },
  content: {
    display: 'flex',
    alignContent: 'center',
    textAlign: 'center',
    flexWrapt: 'noWrap',
  },
}));

function InfoItem({ width }) {
  const classes = useStyles();
  const isSmallScreen = /xs|sm/.test(width);
  const iconProps = {
    fontSize: isSmallScreen ? "default" : "large"
  };
  const buttonProps = {
    size: isSmallScreen ? "small" : "medium",
  };

  return (
    <Box className={classes.grid}>
      <Hidden smDown>
        <Box>
          <Button href="tel:03024037677" startIcon={<Phone {...iconProps} />} {...buttonProps}>
            030 240 376 77
        </Button>
          <Button href="mailto:info@milvik.org"  startIcon={<Mail {...iconProps} />} {...buttonProps}>
            info@milvik.org
        </Button>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <IconButton href="tel:03024037677" component="a">
          <Phone {...iconProps} />
        </IconButton>
        <IconButton href="mailto:info@milvik.org" component="a">
          <Mail {...iconProps} />
        </IconButton>
      </Hidden>
    </Box >
  );
}

export default withWidth()(InfoItem);
