
import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({

    link: {
        textDecoration: 'none',
    },
}));


function HamburgerMenu(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <div>
                <IconButton edge="start" onClick={handleClick}
                    aria-label="menu" aria-controls="simple-menu" aria-haspopup="true">
                    <MenuIcon fontSize="default" />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}><Link className={classes.link} to="/"><Button>Home</Button></Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link className={classes.link} to="/services"><Button>Leistungen</Button></Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link className={classes.link} to="/contact"><Button>Kontakt</Button></Link></MenuItem>
                </Menu>
            </div>
        </React.Fragment>
    );
}

export default (HamburgerMenu);


