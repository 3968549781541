import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  item: {
    padding: theme.spacing(2),
  },
  title: {
    display: 'block',
    textAlign: 'center',
  },
  desc: {
    display: 'block',
    textAlign: 'center',
  },
  cardHeader: {
    color: 'white',
    whiteSpace: 'normal',
    alignItems: 'center',
    display: 'block',
  },
  cardContent: {
    padding: theme.spacing(1),
  },
  card: {
    // minHeight: 200,
    padding: theme.spacing(0),
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    alignItems: 'center'
  },
}));

export default function InfoCard(props) {
  const classes = useStyles();
  const { info } = props;

  return (
    <Grid item xs={10} sm={info.sm} className={classes.item}>
      <Box borderColor="grey.100" border={2} borderRadius={6}>
        <CardActionArea component="a" href={info.href}>
          <Card className={classes.card}>
            <CardHeader
              title={
                <Typography variant="h4" className={classes.title}>
                  {info.title}
                </Typography >
              }
              className={info.cardHeader}
            />
            <CardContent className={classes.cardContent}>
              <Typography variant={info.contentVariant} className={classes.desc}>
                {info.description}
              </Typography >
            </CardContent>
            <CardActions>
            </CardActions>
          </Card>
        </CardActionArea>
      </Box>
    </Grid>
  );
}

InfoCard.propTypes = {
  info: PropTypes.object,
};
