import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
// import BackgroundImage from '../../static/img/background.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  mainGrid: {
    padding: theme.spacing(1),
    height: 200
  },
  item: {
    padding: theme.spacing(2)
    // [theme.breakpoints.up('md')]: {
    //   padding: theme.spacing(6),
    //   paddingRight: 0,
    // },
  }
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  const { post } = props;

  return (
    <React.Fragment>
      <Box
        className={classes.root}
        style={{ backgroundImage: `${post.image}` }}
      >
        <Box borderColor="grey.100" border={2} borderRadius={0}>
          <Grid container className={classes.mainGrid}>
            <Grid item className={classes.item}>
              <Typography
                component="h1"
                variant="h3"
                color="inherit"
                gutterBottom
              >
                {post.title}
              </Typography>
              <Typography variant="h5" color="inherit" paragraph>
                {post.description}
              </Typography>
              <Typography variant="caption">{post.linkText}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.object
};
