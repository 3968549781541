import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "react-router-dom/Link";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  grid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    background: "white",
    opacity: "0.85",
    // color: 'black',
    padding: theme.spacing(0)
  },
  item: {
    // display: 'flex',
    // flexWrapt: 'noWrap',
  },
  bottom: {
    // display: 'flex',
  },
  link: {
    textDecoration: "none"
  }
}));

function Footer() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container className={classes.grid}>
        <Grid item className={classes.top}>
          <Link to="/privacyDataProtection" className={classes.link}>
            <Button component="span" size="small">
              Datenschutz
            </Button>
          </Link>
          |
          <Link to="/Impressum" className={classes.link}>
            <Button component="span" size="small">
              Impressum
            </Button>
          </Link>
        </Grid>
        <Grid item className={classes.bottom}>
          <Typography variant="caption">© 2020 MILVIK GmbH</Typography>
        </Grid>
      </Grid>
      {/* <div className={classes.sticky}> Hello </div> */}
    </React.Fragment>
  );
}

export default Footer;
