import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

const middleware = [thunk];

const initialState = {
  count: 345,
  title: "Example",
  num: "xx",
  counterHidden: true,
  navigationHidden: false,
  openedCard: 1
};

const rootReducer = (state = initialState, action) => {
  console.log("counterReducer is running ..", action.payload);
  switch (action.type) {
    case "INCREMENT":
      return { ...state, count: state.count + 1, title: "Plus Clicked!" };
    case "DECREMENT":
      return { ...state, count: state.count - 1, title: "Minus Clicked!" };
    case "UPDATE_TITLE":
      return { ...state, num: action.payload };
    case "SHOW_HIDE_COUNTER":
      return { ...state, counterHidden: !state.counterHidden };
    case "SHOW_HIDE_NAVIGATION":
      return { ...state, navigationHidden: !state.navigationHidden };
    case "OPEN_CARD":
      return { ...state, openedCard: action.payload };
    default:
      return state;
  }
};

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware)
  // other store enhancers if any
);
const store = createStore(rootReducer, enhancer);

// STORE
// const store = createStore(
//   rootReducer,
//   applyMiddleware(...middleware),
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

export default store;
